<template>
  <a :class="className" href="#" @click.prevent="forceFileDownload()"><flixIcon :id="'download'" /> {{ $t('message.icalDownload') }}</a>
</template>
<script>

export default {
  components: {},
  props: {
    entry: Object,
    className: Object,
    assistent: Object
  },
  data () {
    return {
      begin: this.$createDate(this.entry.begin),
      end: this.$createDate(this.entry.end),
      date: this.$createDate(this.entry.date)
    }
  },
  methods: {
    forceFileDownload () {
      var flix_url = this.$flix_url.split('https://').join('').split('http://').join('').split('www.').join('')

      var response = ''
      response += 'BEGIN:VCALENDAR'
      response += '\n'
      response += 'VERSION:2.0'
      response += '\n'
      response += 'PRODID:-//' + flix_url + '//' + this.$flix_brand + ' 1.0//' + 'DE'
      response += '\n'
      response += 'CALSCALE:GREGORIAN'
      response += '\n'
      response += 'METHOD:PUBLISH'
      response += '\n'
      response += 'BEGIN:VTIMEZONE'
      response += '\n'
      response += 'TZID:Europe/Berlin'
      response += '\n'
      response += 'X-LIC-LOCATION:Europe/Berlin'
      response += '\n'
      response += 'BEGIN:DAYLIGHT'
      response += '\n'
      response += 'TZOFFSETFROM:+0100'
      response += '\n'
      response += 'TZOFFSETTO:+0200'
      response += '\n'
      response += 'TZNAME:CEST'
      response += '\n'
      response += 'DTSTART:19700329T020000'
      response += '\n'
      response += 'RRULE:FREQ=YEARLY;INTERVAL=1;BYDAY=-1SU;BYMONTH=3'
      response += '\n'
      response += 'END:DAYLIGHT'
      response += '\n'
      response += 'BEGIN:STANDARD'
      response += '\n'
      response += 'TZOFFSETFROM:+0200'
      response += '\n'
      response += 'TZOFFSETTO:+0100'
      response += '\n'
      response += 'TZNAME:CEST'
      response += '\n'
      response += 'DTSTART:19701025T030000'
      response += '\n'
      response += 'RRULE:FREQ=YEARLY;INTERVAL=1;BYDAY=-1SU;BYMONTH=10'
      response += '\n'
      response += 'END:STANDARD'
      response += '\n'
      response += 'END:VTIMEZONE'
      response += '\n'
      response += 'BEGIN:VEVENT'
      response += '\n'
      response += 'SUMMARY:' + this.getTitle() + ' | ' + this.entry.email + ' | ' + '#' + this.entry.booking_id
      response += '\n'
      response += 'UID:' + this.entry.booking_id + '@' + flix_url
      response += '\n'
      response += 'DTSTART;TZID=Europe/Berlin:' + this.begin.getFullYear() + this.$getNullsBefore((this.begin.getMonth() + 1)) + this.$getNullsBefore(this.begin.getDate()) + 'T' + this.$getNullsBefore(this.begin.getHours()) + this.$getNullsBefore(this.begin.getMinutes()) + '00'
      response += '\n'
      response += 'DTEND;TZID=Europe/Berlin:' + this.end.getFullYear() + this.$getNullsBefore((this.end.getMonth() + 1)) + this.$getNullsBefore(this.end.getDate()) + 'T' + this.$getNullsBefore(this.end.getHours()) + this.$getNullsBefore(this.end.getMinutes()) + '00'
      response += '\n'
      response += 'DTSTAMP:' + this.date.getFullYear() + this.$getNullsBefore((this.date.getMonth() + 1)) + this.$getNullsBefore(this.date.getDate()) + 'T' + this.$getNullsBefore(this.date.getHours()) + this.$getNullsBefore(this.date.getMinutes()) + '00Z'
      response += '\n'

      if (this.entry.service !== null && typeof this.entry.service === 'object' && typeof this.entry.service.title === 'string') {
        response += 'DESCRIPTION:' + this.entry.service.title + ' | ' + this.entry.service.description
        response += '\n'
        response += 'CATEGORIES:' + this.entry.service.short.join(', ')
        response += '\n'
      }

      response += 'SEQUENCE:0'
      response += '\n'
      response += 'STATUS:CONFIRMED'
      response += '\n'
      response += 'TRANSP:OPAQUE'
      response += '\n'
      response += 'END:VEVENT'
      response += '\n'
      response += 'END:VCALENDAR'

      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.$flix_brand + '-' + this.entry.booking_id + '.ics')
      document.body.appendChild(link)
      link.click()
    },
    getTitle () {
      if (typeof this.assistent === 'object' && typeof this.assistent.title !== 'undefined') {
        return this.assistent.title
      }
      return this.$store.getters.assistentsByID[this.entry.form].title
    }

  }
}
</script>
<style lang="sass" scoped>
</style>
